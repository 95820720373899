import { graphql } from 'gatsby';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import PostCard from '../components/postCard/postCard';
import SeoPress from '../components/seoPress/seoPress';

const JournalPage = ({ data }) => {
  return (
    <Layout navbarOnWhite={true}>
      <SeoPress
        seo={data.wpSitePage.seo}
        title={data.wpSitePage.title}
        uri={`${data.wpSitePage.slug}`}
      />

      <main>
        <header className="pt-7 pb-5" style={{ minHeight: 'auto' }}>
          <h1>{data.wpSitePage.title}</h1>
        </header>

        <section>
          <Container fluid={true} className="px-3 px-lg-5">
            {data.allWpPost.nodes.length > 0 ? (
              <Row>
                {data.allWpPost.nodes.map((post, index) => (
                  <Col xs={12} md={6} className="mb-3 mb-lg-5" key={index}>
                    <PostCard post={post} />
                  </Col>
                ))}
              </Row>
            ) : (
              <>No Posts Found</>
            )}
          </Container>
        </section>

        <section className="pt-4 pt-md-5 pb-6 pb-md-7">
          <NewsletterBanner />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpSitePage(databaseId: { eq: 27 }) {
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        ...cardPost
      }
    }
  }
`;

export default JournalPage;
